<template>

    <div>

        <div class="inplay_games" v-if="game != null && game!=undefined">
            <div class="inplay_selector">
                <div class="s1" :class="{'active_border': s===0}"
                     @click="filterGames(0)" > 전체</div>
                <div class="s1" :class="{'active_border': s===sportsConst.GAME_KIND_1X2}"
                     @click="filterGames(sportsConst.GAME_KIND_1X2)"> 1x2</div>
                <div class="s1" :class="{'active_border': s===sportsConst.GAME_KIND_HANDICAP}"
                     @click="filterGames(sportsConst.GAME_KIND_HANDICAP)"> 핸디캡</div>
                <div class="s1" :class="{'active_border': s===sportsConst.GAME_KIND_OVERUNDER}"
                     @click="filterGames(sportsConst.GAME_KIND_OVERUNDER)"> 오버언더</div>
                <div class="s1"
                     :class="{'active_border': s!==0&&s!==sportsConst.GAME_KIND_1X2&&s!==sportsConst.GAME_KIND_HANDICAP&&s!==sportsConst.GAME_KIND_OVERUNDER}"
                     @click="filterGames(sportsConst.GAME_KIND_OTHER)"> 기타</div>
            </div>

            <!--축구-->
            <div class="igame"
                 v-if="game != null && game.typeId === inplayConst.SPORTS_ID_SOCCER && game.timeStatus === inplayConst.TIME_STATUS_MATCH_INPLAY">
                <!-- 풀타임 승무패 100 soccerFullTimeResult-->
                <div
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_RESULT_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_RESULT_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        풀타임 승무패
                    </h4>

                    <way3 v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_RESULT_KEY]"
                          :item="soccer">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>

                <!--풀타임 오버언더 101 soccerFullTimeOverUnderMatchGoals-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_MATCH_GOALS_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_MATCH_GOALS_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER|| s === 0)">
                    <h4>
                        풀타임 오버언더 MATCH GOALS
                    </h4>
                    <over-under
                            v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_MATCH_GOALS_KEY]"
                            :key="index" :item="soccer">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>

                </div>

                <!--풀타임 오버언더 102-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_ALTERNATIVE_MATCH_GOALS_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_ALTERNATIVE_MATCH_GOALS_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 오버언더 ALTERNATIVE MATCH GOALS-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_ALTERNATIVE_MATCH_GOALS_KEY]"-->
<!--                            :key="index" :item="soccer">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->

<!--                </div>-->

                <!--풀타임 스코어 홀짝 103-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_GOALS_ODD_EVEN_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_GOALS_ODD_EVEN_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 스코어 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULLTIME_GOALS_ODD_EVEN_KEY]"-->
<!--                            :key="index" :item="soccer">-->
<!--                        <span slot="homeName" class="n">홀수</span>-->
<!--                        <span slot="awayName" class="n">짝수</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--풀타임 3way핸디캡 104-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULL_TIME_WIN_FAIL_HANDICAP_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULL_TIME_WIN_FAIL_HANDICAP_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 핸디캡 3WAY-->
<!--                    </h4>-->
<!--                    <handicap-way3-->
<!--                            v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FULL_TIME_WIN_FAIL_HANDICAP_KEY]"-->
<!--                            :key="index" :item="soccer">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </handicap-way3>-->
<!--                </div>-->

                <!-- 전반전 승무패 150-->
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_HALFTIME_RESULT_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_HALFTIME_RESULT_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        전반전 승무패
                    </h4>
                    <way3 v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_HALFTIME_RESULT_KEY]"
                          :item="soccer">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>
                </div>

                <!--전반전 오버언더 151-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FIRST_HALF_GOALS_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FIRST_HALF_GOALS_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        전반전 오버언더
                    </h4>
                    <over-under
                            v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_FIRST_HALF_GOALS_KEY]"
                            :key="index" :item="soccer">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--전반전 핸디캡 152-->
                <div class="games" v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_HANDICAP_1ST_HALF_KEY] !== undefined
            && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_HANDICAP_1ST_HALF_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        전반전 핸디캡
                    </h4>
                    <handicap-way3
                            v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_HANDICAP_1ST_HALF_KEY]"
                            :key="index" :item="soccer">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap-way3>
                </div>

                <!--전반전 양팀모두 득점153-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_1ST_HALF_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_1ST_HALF_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        전반전 양팀 모두 득점-->
<!--                    </h4>-->
<!--                    <way2-yes-no-->
<!--                            v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_1ST_HALF_KEY]"-->
<!--                            :key="index" :item="soccer">-->
<!--                        <span slot="homeName" class="n">예</span>-->
<!--                        <span slot="awayName" class="n">아니오</span>-->
<!--                    </way2-yes-no>-->
<!--                </div>-->

                <!-- 후반전 승무패 170-->
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_TO_WIN_2AND_HALF_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_TO_WIN_2AND_HALF_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        후반전 승무패
                    </h4>
                    <way3 v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_TO_WIN_2AND_HALF_KEY]"
                          :item="soccer">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>
                </div>
                <!--후반전 양팀모두 득점171-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_2AND_HALF_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_2AND_HALF_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        후반전 양팀 모두 득점-->
<!--                    </h4>-->
<!--                    <way2-yes-no-->
<!--                            v-for="(soccer,index) in game.oddsMaps[inplayConst.ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_2AND_HALF_KEY]"-->
<!--                            :key="index" :item="soccer">-->
<!--                        <span slot="homeName" class="n">예</span>-->
<!--                        <span slot="awayName" class="n">아니오</span>-->
<!--                    </way2-yes-no>-->
<!--                </div>-->


            </div>

            <!--농구-->
            <div class="igame"
                 v-if="game != null && game.typeId === inplayConst.SPORTS_ID_BASKETBALL && game.timeStatus === inplayConst.TIME_STATUS_MATCH_INPLAY">
                <!--농구 풀타임 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_MONEY_LINE_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_MONEY_LINE_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        풀타임 승패
                    </h4>
                    <way2 v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_MONEY_LINE_KEY]"
                          :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>

                </div>
                <!--농구 풀타임 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_RUN_LINE_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_RUN_LINE_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        풀타임 핸디캡 Spread
                    </h4>
                    <handicap
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_RUN_LINE_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
                <!--농구 풀타임 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_TOTAL_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_TOTAL_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        풀타임 TOTAL
                    </h4>
                    <over-under
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_TOTAL_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
                <!--농구 풀타임 오버언더 -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_ALTERNATIVE_TOTAL_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_ALTERNATIVE_TOTAL_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_ALTERNATIVE_TOTAL_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->
                <!--농구 풀타임 홀짝-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_MATCH_ODD_EVEN_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_MATCH_ODD_EVEN_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_FULLTIME_MATCH_ODD_EVEN_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">홀</span>-->
<!--                        <span slot="awayName" class="n">짝</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--농구 1 Half 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        1 HALF 승패
                    </h4>
                    <way2 v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1_KEY]"
                          :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>

                </div>
                <!--농구 1 Half 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        1 HALF 핸디캡 Spread
                    </h4>
                    <handicap
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
                <!--농구 1 Half 오버언더-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        1 HALF TOTAL
                    </h4>
                    <over-under
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        1 HALF ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->
                <!--농구 1 Half 홀짝-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1].length>0 &&(s===sportsConst.GAME_KIND_OTHER|| s === 0)">-->
<!--                    <h4>-->
<!--                        1 HALF 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">홀</span>-->
<!--                        <span slot="awayName" class="n">짝</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--농구 2 Half 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        2 HALF 승패
                    </h4>
                    <way2 v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2_KEY]"
                          :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>

                </div>
                <!--농구 2 Half 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        2 HALF 핸디캡 Spread
                    </h4>
                    <handicap
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
                <!--농구 2 Half 오버언더-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        2 HALF TOTAL
                    </h4>
                    <over-under
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        2 HALF ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->
                <!--농구 2 Half 홀짝-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        2 HALF 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">홀</span>-->
<!--                        <span slot="awayName" class="n">짝</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--농구 1Q 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1Q_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2|| s === 0)">
                    <h4>
                        1Q 승패
                    </h4>
                    <way2 v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1Q_KEY]"
                          :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>

                </div>
                <!--농구 1Q 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1Q_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP|| s === 0)">
                    <h4>
                        1Q 핸디캡 Spread
                    </h4>
                    <handicap
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
                <!--농구 1Q 오버언더-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1Q_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        1Q TOTAL
                    </h4>
                    <over-under
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1Q_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        1Q ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1Q_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->
                <!--농구 1Q 홀짝-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1Q_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        1Q 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1Q_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">홀</span>-->
<!--                        <span slot="awayName" class="n">짝</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--농구 2Q 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2Q_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        2Q 승패
                    </h4>
                    <way2 v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2Q_KEY]"
                          :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>

                </div>
                <!--농구 2Q 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2Q_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        2Q 핸디캡 Spread
                    </h4>
                    <handicap
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
                <!--농구 2Q 오버언더-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2Q_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        2Q TOTAL
                    </h4>
                    <over-under
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
               <!-- <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2Q_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        2Q ALTERNATIVE TOTAL
                    </h4>
                    <over-under
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>-->
                <!--농구 2Q 홀짝-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2Q_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        2Q 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2Q_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">홀</span>-->
<!--                        <span slot="awayName" class="n">짝</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--농구 3Q 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_3Q_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_3Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        3Q 승패
                    </h4>
                    <way2 v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_3Q_KEY]"
                          :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>

                </div>
                <!--농구 3Q 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_3Q_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_3Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        3Q 핸디캡 Spread
                    </h4>
                    <handicap
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_3Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
                <!--농구 3Q 오버언더-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_3Q_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_3Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        3Q TOTAL
                    </h4>
                    <over-under
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_3Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_3Q_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_3Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        3Q ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_3Q_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->
                <!--농구 3Q 홀짝-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_3Q_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_3Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        3Q 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_3Q_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">홀</span>-->
<!--                        <span slot="awayName" class="n">짝</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--농구 4Q 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_4Q_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_4Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        4Q 승패
                    </h4>
                    <way2 v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_4Q_KEY]"
                          :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>

                </div>
                <!--농구 4Q 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_4Q_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_4Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        4Q 핸디캡 Spread
                    </h4>
                    <handicap
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_4Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
                <!--농구 4Q 오버언더-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_4Q_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_4Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        4Q TOTAL
                    </h4>
                    <over-under
                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_4Q_KEY]"
                            :key="index" :item="basketball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_4Q_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_4Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        4Q ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_4Q_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->
                <!--농구 4Q 홀짝-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_4Q_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_4Q_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        4Q 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(basketball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_4Q_KEY]"-->
<!--                            :key="index" :item="basketball">-->
<!--                        <span slot="homeName" class="n">홀</span>-->
<!--                        <span slot="awayName" class="n">짝</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

            </div>

            <!--야구-->
            <div class="igame"
                 v-if="game != null && game.typeId === inplayConst.SPORTS_ID_BASEBALL && game.timeStatus === inplayConst.TIME_STATUS_MATCH_INPLAY">
                <!--야구 풀타임 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_MONEY_LINE_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_MONEY_LINE_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        풀타임 승패
                    </h4>
                    <way2 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_MONEY_LINE_KEY]"
                          :key="index" :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>

                <!--야구 풀타임 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_RUN_LINE_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_RUN_LINE_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        풀타임 핸디캡 2WAY
                    </h4>
                    <handicap
                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_RUN_LINE_KEY]"
                            :key="index" :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_RUN_LINE_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_RUN_LINE_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 핸디캡 2WAY ALTERNATIVE-->
<!--                    </h4>-->
<!--                    <handicap-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_RUN_LINE_KEY]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </handicap>-->
<!--                </div>-->

                <!--야구 풀타임 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_TOTAL_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_TOTAL_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER|| s === 0)">
                    <h4>
                        풀타임 TOTAL
                    </h4>
                    <over-under
                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_TOTAL_KEY]"
                            :key="index" :item="baseball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_GAME_TOTAL_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_GAME_TOTAL_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_GAME_TOTAL_KEY]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->

                <!--야구 풀타임 3way핸디캡-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_WINFAIL_HANDICAP_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_WINFAIL_HANDICAP_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 핸디캡 3WAY-->
<!--                    </h4>-->
<!--                    <handicap-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_FULLTIME_WINFAIL_HANDICAP_KEY]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </handicap-way3>-->
<!--                </div>-->


                <!--야구 1-3이닝 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_3_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_3_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        1-3이닝 승패
                    </h4>
                    <way2 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_3_KEY]"
                          :key="index" :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>
                <!--야구 1-3이닝 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_3_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_3_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        1-3이닝 핸디캡
                    </h4>
                    <handicap
                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_3_KEY]"
                            :key="index" :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_3_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_3_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        1-3이닝 핸디캡 ALTERNATIVE-->
<!--                    </h4>-->
<!--                    <handicap-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_3]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </handicap>-->
<!--                </div>-->
                <!--야구 1-3이닝 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_3_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_3_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        1-3이닝 TOTAL
                    </h4>
                    <over-under
                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_3_KEY]"
                            :key="index" :item="baseball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_3_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_3_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        1-3이닝 ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_3_KEY]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->

                <!--야구 1-5이닝 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_5_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_5_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        1-5이닝 승패
                    </h4>
                    <way2 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_5_KEY]"
                          :key="index" :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>
                <!--야구 1-5이닝 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_5_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_5_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        1-5이닝 핸디캡
                    </h4>
                    <handicap
                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_5_KEY]"
                            :key="index" :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_5_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_5_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">-->
<!--                    <h4>-->
<!--                        1-5이닝 핸디캡 ALTERNATIVE-->
<!--                    </h4>-->
<!--                    <handicap-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_5_KEY]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </handicap>-->
<!--                </div>-->
                <!--야구 1-5이닝 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_5_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_5_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        1-5이닝 TOTAL
                    </h4>
                    <over-under
                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_5_KEY]"
                            :key="index" :item="baseball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_5_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_5_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        1-5이닝 ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_5_KEY]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->

                <!--야구 1-7이닝 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_7_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_7_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        1-7이닝 승패
                    </h4>
                    <way2 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_7_KEY]"
                          :key="index" :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>
                <!--야구 1-7이닝 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_7_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_7_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        1-7이닝 핸디캡
                    </h4>
                    <handicap
                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_7_KEY]"
                            :key="index" :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_7_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_7_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">-->
<!--                    <h4>-->
<!--                        1-7이닝 핸디캡 ALTERNATIVE-->
<!--                    </h4>-->
<!--                    <handicap-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_7_KEY]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </handicap>-->
<!--                </div>-->
                <!--야구 1-7이닝 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_7_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_7_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        1-7이닝 TOTAL
                    </h4>
                    <over-under
                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_TOTAL_7_KEY]"
                            :key="index" :item="baseball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_7_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_7_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        1-7이닝 ALTERNATIVE TOTAL-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_7_KEY]"-->
<!--                            :key="index" :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->

                <!-- 이닝별 승무패 -->
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_1N_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_1N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        1이닝 승무패
                    </h4>
                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_1N_KEY]"
                          :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_2N_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_2N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        2이닝 승무패
                    </h4>
                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_2N_KEY]"
                          :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_3N_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_3N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        3이닝 승무패
                    </h4>
                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_3N_KEY]"
                          :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_4N_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_4N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        4이닝 승무패
                    </h4>
                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_4N_KEY]"
                          :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_5N_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_5N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        5이닝 승무패
                    </h4>
                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_5N_KEY]"
                          :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_6N_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_6N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        6이닝 승무패
                    </h4>
                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_6N_KEY]"
                          :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_7N_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_7N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        7이닝 승무패
                    </h4>
                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_7N_KEY]"
                          :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_8N_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_8N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        8이닝 승무패
                    </h4>
                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_WINNER_8N_KEY]"
                          :item="baseball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <!-- 안타수 승무패 -->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_1N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_1N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        1이닝 안타수 승무패-->
<!--                    </h4>-->
<!--                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_1N_KEY]"-->
<!--                          :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_2N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_2N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        2이닝 안타수 승무패-->
<!--                    </h4>-->
<!--                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_2N_KEY]"-->
<!--                          :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_3N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_3N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        3이닝 안타수 승무패-->
<!--                    </h4>-->
<!--                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_3N_KEY]"-->
<!--                          :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_4N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_4N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        4이닝 안타수 승무패-->
<!--                    </h4>-->
<!--                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_4N_KEY]"-->
<!--                          :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_5N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_5N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        5이닝 안타수 승무패-->
<!--                    </h4>-->
<!--                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_5N_KEY]"-->
<!--                          :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_6N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_6N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        6이닝 안타수 승무패-->
<!--                    </h4>-->
<!--                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_6N_KEY]"-->
<!--                          :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_7N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_7N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        7이닝 안타수 승무패-->
<!--                    </h4>-->
<!--                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_7N_KEY]"-->
<!--                          :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_8N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_8N_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">-->
<!--                    <h4>-->
<!--                        8이닝 안타수 승무패-->
<!--                    </h4>-->
<!--                    <way3 v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_MOST_HIT_8N_KEY]"-->
<!--                          :item="baseball">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </way3>-->
<!--                </div>-->

                <!--안타수 오버,무,언더-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_1N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_1N_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        1이닝 안타수 오버,무,언더-->
<!--                    </h4>-->
<!--                    <over-under-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_1N_KEY]"-->
<!--                            :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="drawName" class="n">무승부</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under-way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_2N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_2N_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        2이닝 안타수 오버,무,언더-->
<!--                    </h4>-->
<!--                    <over-under-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_2N_KEY]"-->
<!--                            :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="drawName" class="n">무승부</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under-way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_3N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_3N_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        3이닝 안타수 오버,무,언더-->
<!--                    </h4>-->
<!--                    <over-under-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_3N_KEY]"-->
<!--                            :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="drawName" class="n">무승부</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under-way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_4N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_4N_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        4이닝 안타수 오버,무,언더-->
<!--                    </h4>-->
<!--                    <over-under-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_4N_KEY]"-->
<!--                            :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="drawName" class="n">무승부</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under-way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_5N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_5N_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        5이닝 안타수 오버,무,언더-->
<!--                    </h4>-->
<!--                    <over-under-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_5N_KEY]"-->
<!--                            :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="drawName" class="n">무승부</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under-way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_6N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_6N_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        6이닝 안타수 오버,무,언더-->
<!--                    </h4>-->
<!--                    <over-under-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_6N_KEY]"-->
<!--                            :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="drawName" class="n">무승부</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under-way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_7N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_7N_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        7이닝 안타수 오버,무,언더-->
<!--                    </h4>-->
<!--                    <over-under-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_7N_KEY]"-->
<!--                            :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="drawName" class="n">무승부</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under-way3>-->
<!--                </div>-->
<!--                <div class="games normal"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_8N_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_8N_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        8이닝 안타수 오버,무,언더-->
<!--                    </h4>-->
<!--                    <over-under-way3-->
<!--                            v-for="(baseball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_8N_KEY]"-->
<!--                            :item="baseball">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="drawName" class="n">무승부</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under-way3>-->
<!--                </div>-->
            </div>

            <!--배구-->
            <div class="igame"
                 v-if="game != null && game.typeId === inplayConst.SPORTS_ID_VOLLEYBALL && game.timeStatus === inplayConst.TIME_STATUS_MATCH_INPLAY">
                <!--배구 풀타임 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_WINNER_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_WINNER_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        풀타임 승패
                    </h4>
                    <way2 v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_WINNER_KEY]"
                          :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>

                <!--배구 풀타임 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_HANDICAP_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_HANDICAP_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        풀타임 핸디캡
                    </h4>
                    <handicap
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_HANDICAP_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--배구 풀타임 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_TOTAL_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_TOTAL_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        풀타임 TOTAL
                    </h4>
                    <over-under
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_TOTAL_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--배구 풀타임 스코어 홀짝 -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_ODD_EVEN_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_ODD_EVEN_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 스코어 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_FULLTIME_ODD_EVEN_KEY]"-->
<!--                            :key="index" :item="vball">-->
<!--                        <span slot="homeName" class="n">홀수</span>-->
<!--                        <span slot="awayName" class="n">짝수</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--배구 1S 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_1S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_1S_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        1S 승패
                    </h4>
                    <way2 v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_1S_KEY]"
                          :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>

                <!--배구 1S 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_1S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_1S_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        1S 핸디캡
                    </h4>
                    <handicap
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_1S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--배구 1S 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_1S_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_1S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        1S TOTAL
                    </h4>
                    <over-under
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_1S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--배구 1S 스코어 홀짝 -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_1S_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_1S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        1S 스코어 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_1S_KEY]"-->
<!--                            :key="index" :item="vball">-->
<!--                        <span slot="homeName" class="n">홀수</span>-->
<!--                        <span slot="awayName" class="n">짝수</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--배구 2S 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_2S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_2S_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        2S 승패
                    </h4>
                    <way2 v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_2S_KEY]"
                          :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>

                <!--배구 2S 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_2S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_2S_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        2S 핸디캡
                    </h4>
                    <handicap
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_2S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--배구 2S 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_2S_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_2S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        2S TOTAL
                    </h4>
                    <over-under
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_2S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--배구 2S 스코어 홀짝 -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_2S_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_2S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        2S 스코어 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_2S_KEY]"-->
<!--                            :key="index" :item="vball">-->
<!--                        <span slot="homeName" class="n">홀수</span>-->
<!--                        <span slot="awayName" class="n">짝수</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--배구 3S 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_3S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_3S_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        3S 승패
                    </h4>
                    <way2 v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_3S_KEY]"
                          :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>

                <!--배구 3S 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_3S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_3S_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        3S 핸디캡
                    </h4>
                    <handicap
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_3S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--배구 3S 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_3S_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_3S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        3S TOTAL
                    </h4>
                    <over-under
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_3S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--배구 3S 스코어 홀짝 -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_3S_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_3S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        3S 스코어 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_3S_KEY]"-->
<!--                            :key="index" :item="vball">-->
<!--                        <span slot="homeName" class="n">홀수</span>-->
<!--                        <span slot="awayName" class="n">짝수</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--배구 4S 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_4S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_4S_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        4S 승패
                    </h4>
                    <way2 v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_4S_KEY]"
                          :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>

                <!--배구 4S 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_4S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_4S_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        4S 핸디캡
                    </h4>
                    <handicap
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_4S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--배구 4S 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_4S_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_4S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        4S TOTAL
                    </h4>
                    <over-under
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_4S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--배구 4S 스코어 홀짝 -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_4S_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_4S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        4S 스코어 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_4S_KEY]"-->
<!--                            :key="index" :item="vball">-->
<!--                        <span slot="homeName" class="n">홀수</span>-->
<!--                        <span slot="awayName" class="n">짝수</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--배구 5S 승패-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_5S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_5S_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        5S 승패
                    </h4>
                    <way2 v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_5S_KEY]"
                          :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way2>
                </div>

                <!--배구 5S 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_5S_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_5S_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        5S 핸디캡
                    </h4>
                    <handicap
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_5S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--배구 5S 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_5S_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_5S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        5S TOTAL
                    </h4>
                    <over-under
                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_5S_KEY]"
                            :key="index" :item="vball">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--배구 5S 스코어 홀짝 -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_5S_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_5S_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        5S 스코어 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_5S_KEY]"-->
<!--                            :key="index" :item="vball">-->
<!--                        <span slot="homeName" class="n">홀수</span>-->
<!--                        <span slot="awayName" class="n">짝수</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->


            </div>


            <!--Ice Hokey-->
            <div class="igame"
                 v-if="game != null && game.typeId === inplayConst.SPORTS_ID_ICEHOKEY&& game.timeStatus === inplayConst.TIME_STATUS_MATCH_INPLAY">
                <!--하키 풀타임 승무패-->
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_MONEY_LINE_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_MONEY_LINE_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        풀타임 승무패
                    </h4>
                    <way3 v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_MONEY_LINE_KEY]"
                          :item="hockey">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>

                <!--하키 풀타임 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_LINE_HANDICAP_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_LINE_HANDICAP_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        풀타임 핸디캡
                    </h4>
                    <handicap
                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_LINE_HANDICAP_KEY]"
                            :key="index" :item="hockey">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--하키 풀타임 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_TOTAL_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_TOTAL_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        풀타임 오버언더
                    </h4>
                    <over-under
                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_TOTAL_KEY]"
                            :key="index" :item="hockey">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--하키 풀타임 ALTERNATIVE 핸디캡-->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_PUCK_LINE_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_PUCK_LINE_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">-->
<!--                    <h4>-->
<!--                        ALTERNATIVE PUCK 핸디캡-->
<!--                    </h4>-->
<!--                    <handicap-->
<!--                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_PUCK_LINE_KEY]"-->
<!--                            :key="index" :item="hockey">-->
<!--                        <span slot="homeName" class="n">{{homeName}}</span>-->
<!--                        <span slot="awayName" class="n">{{awayName}}</span>-->
<!--                    </handicap>-->
<!--                </div>-->

                <!--하키 ALTERNATIVE 오버언더  -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_GAME_TOTAL_KEY] !== undefined-->
<!--                  && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_GAME_TOTAL_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">-->
<!--                    <h4>-->
<!--                        ALTERNATIVE 오버언더-->
<!--                    </h4>-->
<!--                    <over-under-->
<!--                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_GAME_TOTAL_KEY]"-->
<!--                            :key="index" :item="hockey">-->
<!--                        <span slot="homeName" class="n">오버</span>-->
<!--                        <span slot="awayName" class="n">언더</span>-->
<!--                    </over-under>-->
<!--                </div>-->

                <!--하키  스코어 홀짝 -->
<!--                <div class="games"-->
<!--                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ODD_EVEN_KEY] !== undefined-->
<!--                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ODD_EVEN_KEY].length>0 &&(s===sportsConst.GAME_KIND_OTHER || s === 0)">-->
<!--                    <h4>-->
<!--                        풀타임 스코어 홀짝-->
<!--                    </h4>-->
<!--                    <way2-odd-even-->
<!--                            v-for="(vball,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_FULLTIME_ODD_EVEN_KEY]"-->
<!--                            :key="index" :item="vball">-->
<!--                        <span slot="homeName" class="n">홀수</span>-->
<!--                        <span slot="awayName" class="n">짝수</span>-->
<!--                    </way2-odd-even>-->
<!--                </div>-->

                <!--하키 1P 승무패-->
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_1P_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_1P_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        1Period 승무패
                    </h4>
                    <way3 v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_1P_KEY]"
                          :item="hockey">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <!--하키 1P 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_1P_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_1P_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        1Period 핸디캡
                    </h4>
                    <handicap
                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_1P_KEY]"
                            :key="index" :item="hockey">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--하키 1P 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_1P_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_1P_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        1Period 오버언더
                    </h4>
                    <over-under
                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_1P_KEY]"
                            :key="index" :item="hockey">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--하키 2P 승무패-->
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_2P_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_2P_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        2Period 승무패
                    </h4>
                    <way3 v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_2P_KEY]"
                          :item="hockey">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <!--하키 2P 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_2P_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_2P_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        2Period 핸디캡
                    </h4>
                    <handicap
                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_2P_KEY]"
                            :key="index" :item="hockey">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--하키 2P 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_2P_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_2P_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        2Period 오버언더
                    </h4>
                    <over-under
                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_2P_KEY]"
                            :key="index" :item="hockey">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

                <!--하키 3P 승무패-->
                <div class="games normal"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_3P_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_3P_KEY].length>0 &&(s===sportsConst.GAME_KIND_1X2 || s === 0)">
                    <h4>
                        3Period 승무패
                    </h4>
                    <way3 v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_WINNER_3P_KEY]"
                          :item="hockey">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </way3>

                </div>
                <!--하키 3P 핸디캡-->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_3P_KEY] !== undefined
                 && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_3P_KEY].length>0 &&(s===sportsConst.GAME_KIND_HANDICAP || s === 0)">
                    <h4>
                        3Period 핸디캡
                    </h4>
                    <handicap
                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_3P_KEY]"
                            :key="index" :item="hockey">
                        <span slot="homeName" class="n">{{homeName}}</span>
                        <span slot="awayName" class="n">{{awayName}}</span>
                    </handicap>
                </div>

                <!--하키 3P 오버언더  -->
                <div class="games"
                     v-if="game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_3P_KEY] !== undefined
                  && game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_3P_KEY].length>0 &&(s===sportsConst.GAME_KIND_OVERUNDER || s === 0)">
                    <h4>
                        3Period 오버언더
                    </h4>
                    <over-under
                            v-for="(hockey,index) in game.oddsMaps[inplayConst.ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_3P_KEY]"
                            :key="index" :item="hockey">
                        <span slot="homeName" class="n">오버</span>
                        <span slot="awayName" class="n">언더</span>
                    </over-under>
                </div>

            </div>
        </div>

        <div v-if="game != null && game!=undefined">

        </div>

    </div>



</template>

<script>
    import inplayConst from "../../common/inplayConst";

    import Way3 from "./child/Way3";
    import Way2 from "./child/Way2";
    import OverUnder from "./child/OverUnder";
    import Handicap from "./child/Handicap";
    import InplayInfo from "./child/InplayInfo";
    import {
        RECEIVE_HIDE_LOADING,
        RECEIVE_INPLAY_CART_ITEM,
        RECEIVE_INPLAY_CART_ITEM_DEL, RECEIVE_INPLAY_CART_ITEM_DEL_ALL,
        RECEIVE_SHOW_LOADING
    } from "../../store/mutation-types";
    import HandicapWay3 from "./child/HandicapWay3";
    import {mapGetters} from "vuex";
    import sportsConst from "../../common/sportsConst";
    import Way2YesNo from "./child/Way2YesNo";
    import Way2OddEven from "./child/Way2OddEven";
    import WidgetInplay from "./child/WidgetInplay";
    import OverUnderWay3 from "./child/OverUnderWay3";
    import {checkLoginMinxin} from "../../common/mixin";


    export default {
        name: "InplayGameComp",
        components: {
            OverUnderWay3,
            WidgetInplay, Way2OddEven, Way2YesNo, HandicapWay3, InplayInfo, Handicap, OverUnder, Way2, Way3
        },
        props: {
            inplay: {
                type: Object,
                default() {
                    return null
                }
            },
            homeName: {
                type: String,
                default() {
                    return ''
                }
            },
            awayName: {
                type: String,
                default() {
                    return ''
                }
            },

        },
        computed: {
            ...mapGetters({
                'cartItems': 'getInplayCartItems',
                'rankConfig': 'getRankConfig',
                'bonusConfig': 'getBonusConfig',
            }),
        },
        data() {
            return {
                inplayConst,
                sportsConst,
                game: null,
                s: 0
            }
        },
        methods: {
            filterGames(s) {
                this.s = s
            }
        },
        created() {

        },
        destroyed() {
            //console.log('InplayGameComp destoryed')
        },
        watch: {
            inplay: {
                handler() {
                    this.game = this.inplay;
                },
                immediate: true,
                deep: true
            },
        }
    }
</script>

<style scoped>

</style>